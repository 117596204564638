.ventigator-frame1 {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-start;
  cursor: pointer;
  z-index: 1;
}
.airable1 {
  position: relative;
  display: inline-block;
  width: 120px;
  height: 58px;
  flex-shrink: 0;
  mix-blend-mode: normal;
  cursor: pointer;
  z-index: 0;
}
.frame1,
.logo {
  align-self: stretch;
  flex: 1;
  display: flex;
  align-items: flex-start;
}
.frame1 {
  overflow: hidden;
  flex-direction: column;
  justify-content: center;
  z-index: 0;
}
.logo {
  flex-direction: row;
  justify-content: flex-start;
  gap: 21px;
  z-index: 2;
}
.app2,
.history,
.home2 {
  position: relative;
  opacity: 0.5;
  cursor: pointer;
  z-index: 4;
}
.app2:hover,
.history:hover,
.home2:hover {
  font-weight: 600;
  font-family: var(--font-mulish);
}
.app2,
.history {
  z-index: 3;
}
.history {
  z-index: 2;
}
.contacts3 {
  position: relative;
  z-index: 1;
}

.top-nav2 {
  align-self: stretch;
  flex: 1;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: space-between;
  padding: var(--padding-8xs) 0 0;
  z-index: 1;
  text-align: center;
  font-size: 18.65px;
  font-family: var(--font-mulish);
}
.top-bar-frame1 {
  align-self: stretch;
  background-color: var(--color-theme);
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-mini) var(--padding-23xl);
  gap: 53px;
  z-index: 1000;
  position: sticky;
  left: 0;
  top: 0;
}
.contact-us {
  align-self: stretch;
  position: relative;
  line-height: 36px;
  mix-blend-mode: normal;
}
.line-frame-item {
  position: relative;
  border-radius: 3.75px;
  background-color: var(--color-black);
  width: 48px;
  height: 3.4px;
}
.line-frame1 {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 var(--padding-41xl) var(--padding-xl);
}
.if-you-are {
  font-weight: 300;
}
.if-you-are-experiencing-a-medi {
  margin: 0;
}
.cpairablegmailcom {
  font-weight: 600;
  font-family: var(--font-mulish);
  color: var(--color-theme);
}
.span1 {
  color: var(--color-slategray);
}
.blank-line2 {
  margin: 0;
  color: var(--color-theme);
}
.available-times {
  margin: 0;
  font-size: 20px;
}
.text-container {
  flex: 1;
  position: relative;
  line-height: 30px;
  mix-blend-mode: normal;
}
.frame2,
.text-frame {
  align-self: stretch;
  flex: 1;
  align-items: flex-start;
}
.text-frame {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  padding: var(--padding-xl) 0;
  font-size: var(--font-size-5xl);
  color: var(--color-slategray);
  font-family: var(--font-mulish);
}
.frame2 {
  flex-direction: column;
  gap: var(--gap-3xs);
}
.contact-us-frame,
.contacts-frame,
.frame2 {
  display: flex;
  justify-content: flex-start;
}
.contact-us-frame {
  align-self: stretch;
  flex: 1;
  flex-direction: row;
  align-items: center;
  padding: var(--padding-xl) var(--padding-31xl);
  font-size: 36px;
  color: var(--color-black);
  font-family: var(--font-poppins);
}
.contacts-frame {
  position: relative;
  background-color: var(--color-gainsboro);
  width: 100%;
  min-height: 100vh;
  height: 100%;
  flex-direction: column;
  align-items: flex-start;
  gap: var(--gap-xl);
  text-align: left;
  font-size: 28px;
  color: var(--color-white);
  font-family: var(--font-noto-serif-thai);
}
@media screen and (max-width: 900px) {
  .ventigator-frame1 {
    display: none;
  }
  .frame1 {
    flex: 1;
    align-self: stretch;
    height: auto;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding-right: 0;
    box-sizing: border-box;
  }
  .logo {
    display: flex;
  }
  .top-nav2 {
    display: none;
  }
  .contact-us-frame,
  .frame2 {
    flex: unset;
    align-self: stretch;
    height: 600px;
  }
  .contact-us-frame {
    width: auto;
    flex: 1;
    flex-direction: column;
  }
  .contacts-frame {
    height: auto;
  }
}
