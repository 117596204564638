.ventigator-frame2 {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-start;
  cursor: pointer;
  z-index: 1;
}
.airable2 {
  position: relative;
  display: inline-block;
  width: 120px;
  height: 58px;
  flex-shrink: 0;
  mix-blend-mode: normal;
  cursor: pointer;
  z-index: 0;
}
.frame3,
.logo {
  align-self: stretch;
  flex: 1;
  display: flex;
  align-items: flex-start;
}
.frame3 {
  overflow: hidden;
  flex-direction: column;
  justify-content: center;
  z-index: 0;
}
.logo {
  flex-direction: row;
  justify-content: flex-start;
  gap: 21px;
  z-index: 2;
}
.app3,
.home3 {
  position: relative;
}
.home3 {
  opacity: 0.5;
  cursor: pointer;
  z-index: 4;
}
.contacts:hover,
.history:hover,
.home3:hover {
  font-weight: 600;
  font-family: var(--font-mulish);
}
.app3 {
  z-index: 3;
}
.history {
  cursor: pointer;
  z-index: 2;
}
.contacts,
.history {
  position: relative;
  opacity: 0.5;
}
.contacts {
  cursor: pointer;
  z-index: 1;
}
.vector-icon {
  position: relative;
  width: 10.6px;
  height: 4.6px;
}
.top-nav3 {
  align-self: stretch;
  flex: 1;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: space-between;
  padding: var(--padding-8xs) 0 0;
  z-index: 1;
  text-align: center;
  font-size: 18.65px;
  font-family: var(--font-mulish);
}
.top-bar-frame2 {
  align-self: stretch;
  background-color: var(--color-theme);
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-mini) var(--padding-23xl);
  gap: 53px;
  z-index: 1000;
  position: sticky;
}
.download-our-mobile-container {
  align-self: stretch;
  position: relative;
  line-height: 36px;
  mix-blend-mode: normal;
}
.line-frame-inner {
  position: relative;
  border-radius: 3.75px;
  background-color: var(--color-black);
  width: 48px;
  height: 3.4px;
}
.line-frame2 {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 var(--padding-41xl) var(--padding-xl);
}
.access-your-health {
  flex: 1;
  position: relative;
  line-height: 43px;
  font-weight: 300;
  mix-blend-mode: normal;
  padding-right: 70px;
}
.text-frame1 {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-xl) 0;
  font-size: var(--font-size-5xl);
  color: var(--color-slategray);
  font-family: var(--font-mulish);
}
.download {
  position: relative;
  font-size: 13.5px;
  line-height: 45px;
  display: flex;
  font-family: var(--font-poppins);
  color: var(--color-theme);
  text-align: center;
  align-items: center;
  justify-content: center;
  width: 76.5px;
  height: 31.5px;
  mix-blend-mode: normal;
}
.vector-icon1 {
  position: relative;
  width: 15px;
  height: 15px;
}
.btn,
.frame4 {
  display: flex;
  justify-content: flex-start;
}
.btn {
  cursor: pointer;
  border: 1.4px solid var(--color-theme);
  padding: 11px var(--padding-31xl);
  background-color: transparent;
  border-radius: 41px;
  flex-direction: row;
  align-items: center;
  gap: 18px;
}
.frame4 {
  align-self: stretch;
  flex: 1;
  flex-direction: column;
  align-items: flex-start;
  gap: var(--gap-3xs);
}
.airableappui1-icon {
  align-self: stretch;
  flex: 1;
  position: relative;
  border-radius: var(--br-mini);
  width: 100%;
  overflow: hidden;
  height: 90%;
  object-fit: cover;
}
.airableappui1-wrapper {
  align-self: stretch;
  width: auto;
  display: flex;
  gap: 40px;
  flex-direction: row;
  justify-content: flex-end;
}
.download-our-mobile-apps,
.mobile-app {
  display: flex;
  justify-content: flex-start;
}
.download-our-mobile-apps {
  align-self: stretch;
  flex: 1;
  flex-direction: row;
  align-items: center;
  padding: var(--padding-xl) var(--padding-31xl);
  gap: var(--gap-31xl);
  font-size: 36px;
  color: var(--color-black);
  font-family: var(--font-poppins);
}
.mobile-app {
  position: relative;
  background-color: var(--color-gainsboro);
  width: 100%;
  min-height: 100vh;
  height: 100%;
  flex-direction: column;
  align-items: flex-start;
  gap: var(--gap-xl);
  text-align: left;
  font-size: 28px;
  color: var(--color-white);
  font-family: var(--font-noto-serif-thai);
}
@media screen and (max-width: 900px) {
  .ventigator-frame2 {
    display: none;
  }
  .frame3 {
    flex: 1;
    align-self: stretch;
    height: auto;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding-right: 0;
    box-sizing: border-box;
  }
  .logo {
    display: flex;
  }
  .top-nav3 {
    display: none;
  }
  .frame4 {
    flex: unset;
    align-self: stretch;
  }
  .airableappui1-wrapper {
    width: auto;
    align-self: unset;
  }
  .download-our-mobile-apps {
    align-self: stretch;
    width: auto;
    flex: 1;
    flex-direction: column;
  }
  .mobile-app {
    height: auto;
  }
}
