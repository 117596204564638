/* Custom Color for O2 Saturation Cards */
.btn-outline-dark {
  --bs-btn-color: #8b0000 !important;
  --bs-btn-border-color: #8b0000 !important;
  --bs-btn-hover-color: #fff !important;
  --bs-btn-hover-bg: #8b0000 !important;
  --bs-btn-hover-border-color: #8b0000 !important;
  --bs-btn-focus-shadow-rgb: 220, 53, 69 !important;
  --bs-btn-active-color: #fff !important;
  --bs-btn-active-bg: #8b0000 !important;
  --bs-btn-active-border-color: #8b0000 !important;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #8b0000 !important;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #8b0000 !important;
  --bs-gradient: none;
}

.btn-themed {
  background-color: #94a187 !important;
  border-color: #94a187 !important;
}

.btn-themed:hover {
  background-color: #69745e !important;
  border-color: #69745e !important;
}

/* Custom Color for Bootstrap primary */
.color-theme {
background-color: #69745e;
}
.ventigator-logo-nav {
  width: 40px;
  height: 40px;
}
.signout
{
  position: relative;
  opacity: 0.5;
}
.signout {
  cursor: pointer;
  z-index: 1;
}
.custom-badge {
  background-color: white !important;
  color: black !important;
  border-color:grey !important;
}
.btn-trans-lightgreen {
  background-color: transparent !important;
  color: #94a187 !important;
}
.btn-trans-lightgreen:hover {
  border-color: #94a187 !important;
  background-color: #94a187 !important;
  color: white !important;
}

.btn-trans-red {
  background-color: transparent !important;
  color: #8b0000 !important;
}
.btn-trans-red:hover {
  border-color: #8b0000 !important;
  background-color: #8b0000 !important;
  color: white !important;
}
.button-spacing{
  justify-content: center;
  display: flex;
}
.target-O2-with-button{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.provider-code{
   border-color: grey !important;
}