.ventigator-frame {
  cursor: pointer;
  text-decoration: none;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-start;
  z-index: 1;
}
.airable {
  position: relative;
  display: inline-block;
  width: 120px;
  height: 58px;
  flex-shrink: 0;
  mix-blend-mode: normal;
  cursor: pointer;
  z-index: 0;
}
.frame,
.logo {
  align-self: stretch;
  flex: 1;
  display: flex;
  align-items: flex-start;
}
.frame {
  overflow: hidden;
  flex-direction: column;
  justify-content: center;
  z-index: 0;
}
.logo {
  flex-direction: row;
  justify-content: flex-start;
  gap: 21px;
  z-index: 2;
}
.home {
  opacity: 0.5;
  cursor: pointer;
  z-index: 4;
}
.app:hover,
.contacts:hover,
.home:hover {
  font-weight: 600;
  font-family: var(--font-mulish);
}
.app,
.history-og,
.home {
  position: relative;
}
.app {
  opacity: 0.5;
  cursor: pointer;
  z-index: 3;
}
.history-og {
  z-index: 2;
}
.contacts {
  position: relative;
  opacity: 0.5;
}
.contacts {
  cursor: pointer;
  z-index: 1;
}
.top-nav {
  display: flex;
  flex-direction: row;
}
.top-nav {
  align-self: stretch;
  flex: 1;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: space-between;
  padding: var(--padding-8xs) 0 0;
  z-index: 1;
  text-align: center;
  font-size: 18.65px;
  font-family: var(--font-mulish);
}
.top-bar-frame {
  align-self: stretch;
  background-color: var(--color-theme);
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-mini) var(--padding-23xl);
  gap: 53px;
  z-index: 1000;
  position: sticky;
  left: 0;
  top: 0;
}
.history1 {
  align-self: stretch;
  position: relative;
  line-height: 42px;
  mix-blend-mode: normal;
}
.line-frame-child {
  position: relative;
  border-radius: 3.75px;
  background-color: var(--color-black);
  width: 48px;
  height: 3.4px;
}
.line-frame {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 0 0 var(--padding-xl);
}
.history-description,
.ventilator-v1-icon {
  align-self: stretch;
  position: relative;
}
.ventilator-v1-icon {
  flex: 1;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
  object-fit: cover;
}
.history-description {
  padding-top: 20px;
  line-height: 37px;
  font-weight: 300;
  mix-blend-mode: normal;
}
.history-images-frame,
.v1-frame {
  align-self: stretch;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
}
.history-images-frame {
  overflow: hidden;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 100px;
  font-size: 24px;
  color: var(--color-slategray);
  font-family: var(--font-mulish);
}
.history-frame,
.history-frame1 {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.history-frame1 {
  align-self: stretch;
  flex: 1;
  justify-content: flex-start;
  padding: var(--padding-xl) var(--padding-23xl);
  gap: var(--gap-3xs);
  text-align: center;
  font-size: 36px;
  color: var(--color-black);
  font-family: var(--font-poppins);
}
.history-frame {
  position: relative;
  background-color: var(--color-gainsboro);
  width: 100%;
  height: 100%;
  justify-content: center;
  gap: var(--gap-xl);
  text-align: left;
  font-size: 28px;
  color: var(--color-white);
  font-family: var(--font-noto-serif-thai);
}
@media screen and (max-width: 900px) {
  .ventigator-frame {
    display: none;
  }
  .frame {
    flex: 1;
    align-self: stretch;
    height: auto;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding-right: 0;
    box-sizing: border-box;
  }
  .logo {
    display: flex;
  }
  .top-nav {
    display: none;
  }
  .v1-frame {
    flex: unset;
    align-self: stretch;
  }
  .history-images-frame {
    flex-direction: column;
  }
  .history-frame {
    width: auto;
    align-self: unset;
    height: auto;
  }
  .history-description{
    font-size: 20px;
    flex: auto;
  }
}
