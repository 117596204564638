@import url("https://fonts.googleapis.com/css2?family=Noto+Serif+Thai:wght@700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Mulish:wght@300;400;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;700&display=swap");
body {
  margin: 0;
  line-height: normal;
}
:root {
  /* fonts */
  --font-mulish: Mulish;
  --font-poppins: Poppins;
  --font-noto-serif-thai: "Noto Serif Thai";

  /* font sizes */
  --font-size-5xl: 24px;
  --font-size-17xl: 36px;
  --font-size-lg-7: 18.7px;
  --font-size-9xl: 28px;
  --font-size-13xl: 32px;
  --font-size-5xl-9: 24.9px;
  --font-size-18xl-3: 37.3px;
  --font-size-lg: 18px;

  /* Colors */
  --color-gainsboro: #e2e9e4;
  --color-slategray: #7d7987;
  --color-black: #000;
  --color-theme: #69745e;
  --color-white: #fff;
  --f1534-hyfen: #1f1534;
  --color-royalblue: #1e60bc;
  --color-lightgreen: #bfd7c1;

  /* Gaps */
  --gap-xl: 20px;
  --gap-3xs: 10px;
  --gap-33xl-8: 52.8px;
  --gap-9xs-3: 3.3px;
  --gap-5xs-8: 7.8px;
  --gap-2xl: 21px;
  --gap-31xl: 50px;

  /* Paddings */
  --padding-xl: 20px;
  --padding-23xl: 42px;
  --padding-mini: 15px;
  --padding-8xs: 5px;
  --padding-5xs-8: 7.8px;
  --padding-3xs: 10px;
  --padding-31xl: 50px;
  --padding-41xl: 60px;

  /* Border radiuses */
  --br-9xs-8: 3.8px;
  --br-7xs-6: 5.6px;
  --br-61xl: 80px;
  --br-mini: 15px;
  --br-9xs-9: 3.9px;

  --bs-blue: #0d6efd;
  --bs-indigo: #6610f2;
  --bs-purple: #6f42c1;
  --bs-pink: #d63384;
  --bs-red: #dc3545;
  --bs-orange: #fd7e14;
  --bs-yellow: #ffc107;
  --bs-green: #198754;
  --bs-teal: #20c997;
  --bs-cyan: #0dcaf0;
  --bs-white: #fff;
  --bs-gray: #6c757d;
  --bs-gray-dark: #343a40;
  --bs-primary: #0d6efd;
  --bs-secondary: #6c757d;
  --bs-success: #198754;
  --bs-info: #0dcaf0;
  --bs-warning: #ffc107;
  --bs-danger: #dc3545;
  --bs-light: #f8f9fa;
  --bs-dark: #212529;
  --bs-font-sans-serif: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --bs-font-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  --bs-gradient: linear-gradient(180deg, rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0));
}

.ventigator-logo-icon {
  position: relative;
  width: 66.4px;
  height: 66.4px;
  object-fit: cover;
}


/* Portal Login Popup CSS */

.link-portal {
  cursor: pointer;
  border: 0;
  padding: 0 7.769026756286621px;
  background-color: transparent;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 7.77px;
  z-index: 0;
}
.link-portal-child {
  position: relative;
  opacity: 0.5;
  font-size: 18.65px;
  font-family: var(--font-mulish);
  color: var(--color-white);
  text-align: left;
}
.link-portal-child:hover {
  font-weight: 600;
  font-family: var(--font-mulish);
}
.vector-link {
  position: relative;
  width: 10.6px;
  height: 4.6px;
}
.link {
  background: none;
  border: none;
  text-decoration: none;
  color: #69745e;
  font-family: inherit;
  font-size: inherit;
  cursor: pointer;
  padding: 0;
  opacity: 0.5;
}
.dropdown {
  position: relative;
  z-index: 1002;
}
.dropdown-menu {
  position: absolute;
  right: 0;
  top: calc(100% + 0.25rem);
  background-color: white;
  padding: 0.75rem;
  border-radius: 0.25rem;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.1);
  opacity: 0;
  pointer-events: none;
  transform: translateY(-200px);
  transition: opacity 150ms ease-in-out, transform 150ms ease-in-out;
  z-index: 1001;
}
.dropdown.active > .link-portal + .dropdown-menu {
  opacity: 1;
  transform: translateY(0);
  pointer-events: auto;
}
.information-grid {
  display: grid;
  grid-template-columns: repeat(2, max-content);
  gap: 2rem;
}
.dropdown-links {
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
  z-index: inherit;
}
.portalPopupOverlay {
  display: flex;
  flex-direction: column;
  position: fixed;
  inset: 0;
}

/* Hamburger Menu CSS */
.hamburger-menu-child-top-bot,
.hamburger-menu-child-mid {
  position: relative;
  border-radius: 5.6px;
  background-color: var(--color-white);
  width: 27px;
  height: 3px;
}
.hamburger-menu-child-mid {
  width: 23px;
}
.hamburger-menu {
  cursor: pointer;
  border: 0;
  padding: 0;
  background-color: transparent;
  height: 23px;
  display: none;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
  gap: 3.3px;
  z-index: 0;
}
@media screen and (max-width: 900px) {
    .hamburger-menu {
        display: flex;
      }
}
