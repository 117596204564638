@keyframes slide-in-right {
  0% {
    transform: translateX(200px);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}
.sections{
  cursor: pointer;
  text-decoration: none;
  color: inherit;
  position: relative;
  opacity: 0.5;
}
.ventigator-logo {
  position: relative;
  width: 66.4px;
  height: 66.4px;
  background-image: url(/public/ventigatorlogo.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
  cursor: pointer;
  text-decoration: none;
}
.sections:hover,
.hidden-link:hover {
  font-weight: 600;
  font-family: var(--font-mulish);
}
.hidden-menu-bar,
.top-nav1 {
  display: flex;
  align-items: center;
  justify-content: center;
}

.hidden-menu-bar,
.top-nav1 {
  box-sizing: border-box;
}
.top-nav1 {
  width: 239px;
  flex-direction: column;
  gap: 38px;
}
.hidden-menu-bar {
  background-color: var(--color-gainsboro);
  width: 227px;
  flex-direction: row;
  padding: var(--padding-3xs);
  opacity: 0;
  height: 100%;
  max-width: 90%;
  overflow: auto;
  text-align: center;
  font-size: 18.65px;
  color: var(--f1534-hyfen);
  font-family: var(--font-mulish);
}
.hidden-menu-bar.animate {
  animation: 0.25s ease 0s 1 normal forwards slide-in-right;
}

.hidden-link {
  background: none;
  border: none;
  text-decoration: none;
  color: var(--f1534-hyfen);
  font-family: inherit;
  font-size: inherit;
  cursor: pointer;
  padding: 0;
  opacity: 0.5;
}