.logo-home {
  text-decoration: none;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-start;
  z-index: 1;
}
.airable3 {
  position: relative;
  display: inline-block;
  width: 120px;
  height: 58px;
  flex-shrink: 0;
  mix-blend-mode: normal;
  z-index: 0;
}
.frame5,
.logo {
  align-self: stretch;
  flex: 1;
  display: flex;
  align-items: flex-start;
}
.frame5 {
  overflow: hidden;
  flex-direction: column;
  justify-content: center;
  z-index: 0;
}
.logo {
  flex-direction: row;
  justify-content: flex-start;
  gap: 21px;
  z-index: 2;
}
.app4,
.home4 {
  position: relative;
  cursor: pointer;
  z-index: 4;
}
.app4 {
  text-decoration: none;
  color: inherit;
  opacity: 0.5;
  z-index: 3;
}
.app4:hover {
  font-weight: 600;
  font-family: var(--font-mulish);
}
.history7 {
  cursor: pointer;
  z-index: 2;
}
.history7:hover {
  font-size: var(--font-size-lg);
}
.contacts5,
.history7 {
  position: relative;
  opacity: 0.5;
}
.contacts5 {
  cursor: pointer;
  z-index: 1;
}
.contacts5:hover,
.history7:hover {
  font-weight: 600;
  font-family: var(--font-mulish);
}

.top-nav4 {
  align-self: stretch;
  flex: 1;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: space-between;
  padding: var(--padding-8xs) 0 0;
  z-index: 1;
  text-align: center;
  font-size: 18.65px;
  font-family: var(--font-mulish);
}
.navbar-home {
  margin: 0;
  align-self: stretch;
  background-color: var(--color-theme);
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-mini) var(--padding-23xl);
  gap: 53px;
  z-index: 1000;
  position: sticky;
  left: 0;
  top: 0;
  text-align: left;
  font-size: 28px;
  color: var(--color-white);
  font-family: var(--font-noto-serif-thai);
}
.wearable-ventilator {
  align-self: stretch;
  line-height: 43.51px;
}
.gen-description,
.sign-up-now,
.wearable-ventilator {
  position: relative;
  mix-blend-mode: normal;
}
.gen-description {
  align-self: stretch;
  font-size: 27px;
  line-height: 37px;
  font-weight: 300;
  font-family: var(--font-mulish);
  color: var(--color-slategray);
  padding-right: 7px;
}
.sign-up-now {
  font-size: 18.65px;
  line-height: 46.61px;
  display: flex;
  font-family: var(--font-poppins);
  color: var(--color-white);
  text-align: center;
  align-items: center;
  justify-content: center;
  width: 174.8px;
  height: 69.1px;
  flex-shrink: 0;
}
.consult-btn,
.header-text {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.consult-btn {
  cursor: pointer;
  border: 0;
  padding: 0;
  background-color: #94a187;
  border-radius: 42.73px;
  justify-content: center;
}
.consult-btn:hover {
  background-color: var(--color-theme);
}
.header-text {
  align-self: stretch;
  flex: 1;
  justify-content: flex-start;
  gap: 38.85px;
}
.ventilator-image {
  align-self: flex-end;
  flex: 1;
  position: relative;
  max-width: 90%;
  overflow: hidden;
  max-height: 90%;
  object-fit: cover;
}
.description-frame,
.ventilator-image-wrapper {
  align-self: stretch;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-start;
}
.description-frame {
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  padding: var(--padding-xl) var(--padding-23xl) 0;
  text-align: left;
  max-height: max-content;
}
.line-under-header-child {
  flex: 1;
  position: relative;
  width: 80%;
  overflow: hidden;
  height: 0.8px;
}
.line-under-header {
  align-self: center;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 10px var(--padding-23xl);
  box-sizing: border-box;
  width: 90%;
}
.our-services {
  align-self: stretch;
  position: relative;
  line-height: 43.5px;
  mix-blend-mode: normal;
}
.services {
  align-self: stretch;
  flex: 1;
  position: relative;
  font-size: 27px;
  line-height: 43px;
  font-weight: 300;
  font-family: var(--font-mulish);
  color: var(--color-slategray);
  mix-blend-mode: normal;
  padding-left: 75px;
  padding-right: 75px;
  padding-top: 60px;
}
.services-frame {
  align-self: stretch;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding: 0 var(--padding-23xl);
  gap: var(--gap-3xs);
}
.how-it-works {
  align-self: stretch;
  position: relative;
  line-height: 43.5px;
  mix-blend-mode: normal;
  z-index: 0;
}
.line-frame-child {
  position: relative;
  border-radius: 4px;
  background-color: var(--color-black);
  width: 50px;
  height: 3.5px;
}
.line-frame {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 0 0 var(--padding-xl);
  z-index: 1;
}
.steps {
  margin: 0;
  line-height: 37px;
}
.steps-container {
  align-self: stretch;
  flex: 1;
  position: relative;
  line-height: 30px;
  font-weight: 300;
  white-space: pre-wrap;
  mix-blend-mode: normal;
}
.step-outer,
.steps-wrapper,
.steps-frame {
  align-self: stretch;
  flex: 1;
  display: flex;
  align-items: center;
}
.steps-frame {
  border-radius: var(--br-mini);
  background-color: var(--color-white);
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25), 0 4px 4px rgba(0, 0, 0, 0.25);
  flex-direction: column;
  justify-content: space-between;
  padding: var(--padding-3xs);
}
.step-outer,
.steps-wrapper {
  flex-direction: row;
  justify-content: center;
}
.step-outer {
  border-radius: var(--br-mini);
  background-color: var(--color-lightgreen);
  padding: var(--padding-mini);
}
.steps-wrapper {
  gap: var(--gap-31xl);
  z-index: 2;
  font-size: 24.86px;
  color: var(--color-slategray);
  font-family: var(--font-mulish);
}
.gator-1-icon {
  position: absolute;
  margin: 0 !important;
  top: 500px;
  left: -170px;
  width: 334px;
  height: 400px;
  object-fit: cover;
  z-index: 3;
}
.how-it-works-frame,
.how-it-works-parent {
  align-self: stretch;
  flex: 1;
  display: flex;
  flex-direction: column;
}
.how-it-works-parent {
  align-items: center;
  justify-content: center;
  position: relative;
  gap: var(--gap-3xs);
}
.how-it-works-frame {
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-xl) var(--padding-23xl);
}
.gator-frame {
  align-self: stretch;
  height: 314px;
}
.landing-page {
  position: relative;
  background-color: var(--color-gainsboro);
  width: 100%;
  height: 2686px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: var(--gap-xl);
  text-align: center;
  font-size: 37px;
  color: var(--color-black);
  font-family: var(--font-poppins);
}
@media screen and (max-width: 1200px) {
  .line-under-header-child {
    flex: 1;
  }
  .line-under-header {
    align-self: stretch;
    width: auto;
  }
}
@media screen and (max-width: 900px) {
  .logo-home {
    display: none;
  }
  .frame5 {
    flex: 1;
    align-self: stretch;
    height: auto;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding-right: 0;
    box-sizing: border-box;
  }
  .logo {
    display: flex;
  }
  .top-nav4 {
    display: none;
  }
  .header-text {
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    flex: unset;
    align-self: stretch;
  }
  .description-frame,
  .ventilator-image-wrapper {
    align-self: stretch;
    width: auto;
    align-items: flex-start;
    justify-content: flex-start;
    flex: auto;
  }
  .description-frame {
    flex: 1;
    flex-direction: column;
    gap: var(--gap-31xl);
    justify-content: space-between;
    padding-top: var(--padding-31xl);
    padding-right: var(--padding-23xl);
    padding-left: var(--padding-23xl);
    box-sizing: border-box;
  }
  .line-under-header {
    width: 80%;
    align-items: flex-start;
    justify-content: center;
    align-self: center;
    max-width: 1000px;
  }
  .step-outer {
    flex: auto;
    align-self: stretch;
  }
  .steps-wrapper {
    height: auto;
    flex-direction: column;
    gap: 63px;
  }
  .gator-1-icon {
    width: 201px;
    height: 243px;
    position: fixed;
    left: -70px;
    top: 570px;
  }
  .how-it-works-parent {
    height: auto;
  }
  .how-it-works-frame {
    align-self: stretch;
    width: auto;
    flex-direction: column;
    padding-left: var(--padding-23xl);
    padding-right: var(--padding-23xl);
    box-sizing: border-box;
    flex: auto;
  }
  .landing-page {
    height: 5500px;
  }
  .services-frame{
    flex: none;
    margin-bottom: 100px;
  }
  .services {
    padding-left: 0px;
    padding-right: 0px;
  }
  .ventilator-image{
    flex: auto;
    align-self: center;
  }
}
